import { render, staticRenderFns } from "./Compare.vue?vue&type=template&id=85e99670&scoped=true&"
import script from "./Compare.vue?vue&type=script&lang=js&"
export * from "./Compare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e99670",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VDataTable,VSimpleTable,VTabItem,VTabsItems})
